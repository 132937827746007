import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import "./contact.css";
import { RedirectContext } from "../../context/redirect";
import { useInitAuth } from "../../hooks/useInitAuth";
import { useMutation } from "@tanstack/react-query";
import {
  changeStatusLead,
  DadosParceiroType,
  getLead,
  GetLeadResponse,
} from "../../services/onboarding";
import { GET_LEAD_EXIST } from "../../query/constants";
import { SessionContext } from "../../context/session";
import { getLocalStorage } from "../../utils/localStorage";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import PdfAlert from "../../assets/installation/pdf-alert.png";
import { ScreenError } from "../../components/screenError";
import { AxiosError } from "axios";
import { getImage } from "../../services/images";
import { ContactForm } from "./contactForm";
import { isParceiroEnvironment } from "../../services/environment";
import UseNewLead from "../../hooks/useNewLead";
import { getParceiroId } from "../../services/parceiro";
import { textsPath } from "../../services/texts";
import { ModalEmailExistente } from "./ModalEmailExistente";
import { ModalRef } from "../../utils/modal";
import { ModalAguardandoAssinaturaCliente } from "./ModalAguardandoAssinaturaCliente";
import { ModalTermosDeUso } from "../../components/modal/ModalTermsUso/ModelTermosDeUso";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { usePartnerStore } from "../../partner/use-partner-store";

export const Contact = () => {
  const navigate = useNavigate();
  const { getItemSession, setItemSession } = useContext(SessionContext);
  const { setContinueResgistration } = useContext(RedirectContext);
  const [statusLead, setStatusLead] = useState<boolean>(false);
  const [noEditEmail, setNoEditEmail] = useState<boolean>(false);
  const [continueEmail, setContinueEmail] = useState<boolean>(false);
  const [formData, setFormData] = useState({ email: "", name: "", phone: "" });
  const [formValid, setFormValid] = useState(false);
  const [isPoliticaAceita, setIsPoliticaAceita] = useState(false);

  const { email, name, phone } = formData;
  const createLead = UseNewLead();
  const modalEmailExistenteRef = useRef<ModalRef | null>(null);
  const modalAguardandoAssinaturaClienteRef = useRef<ModalRef | null>(null);
  const modalTermosDeUsoRef = useRef<ModalRef | null>(null);

  const openTermosDeUso = () => modalTermosDeUsoRef.current?.open();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { assets, mode, texts } = usePartnerStore();

  useEffect(() => {
    const storageContact = getItemSession("contact");
    const localStoragePath = getLocalStorage("path");
    const emailContinue = getLocalStorage("emailContinue");
    if (storageContact != null) {
      const contactModifyStorage = JSON.parse(storageContact);
      if (
        contactModifyStorage.email &&
        localStoragePath === "/confirm-your-data"
      ) {
        setNoEditEmail(true);
      }
      if (emailContinue) {
        setContinueEmail(true);
      }
    }
  }, []);

  const initAuth = useInitAuth();

  // const existLead = useMutation({
  //   mutationFn: async (email: string) => {
  //     let token = "";
  //     if (executeRecaptcha) {
  //       token = await executeRecaptcha("get_lead");
  //     }
  //     const response = await getLead(email, token);
  //     return response;
  //   },
  //   mutationKey: [GET_LEAD_EXIST],
  //   onSuccess: (data) => {
  //     const statusLead = data.status;

  //     if (statusLead === "PENDENTE") {
  //       // PENDENTE
  //       return modalEmailExistenteRef.current?.open();
  //     } else if (statusLead === "VALIDACAO_FATURA") {
  //       // VALIDACAO_FATURA
  //       return setStatusLead(true);
  //     }
  //   },
  //   onError(erro: AxiosError) {
  //     const status = erro.response?.data as string;
  //     if (status === "LEAD PENDENTE NOT_FOUND") {
  //       initAuth.mutate({ email, name });
  //     }
  //     const stausError = status.split(":")[1].trim();
  //     if (stausError === "VALIDACAO_FATURA") {
  //       return setStatusLead(true);
  //     }
  //   },
  // });

  const existLead = useMutation({
    mutationFn: async (email: string) => {
      let token = "";
      if (executeRecaptcha) {
        token = await executeRecaptcha("get_lead");
      }
      const response = await getLead(email, token);
      return response;
    },

    mutationKey: [GET_LEAD_EXIST],

    onSuccess: (data) => {
      const statusLead = data.status;

      if (statusLead === "PENDENTE") {
        // PENDENTE
        handleLeadPendente(data);
        return;
      }

      if (statusLead === "VALIDACAO_FATURA") {
        // VALIDACAO_FATURA
        return setStatusLead(true);
      }
    },

    onError(erro: AxiosError) {
      const status = erro.response?.status;
      if (status === 404) {
        nextStep();
      }
    },
  });

  const handleLeadPendente = (lead: GetLeadResponse) => {
    const isCustomerMode = mode === "CUSTOMER";

    if (isCustomerMode) {
      modalEmailExistenteRef.current?.open();
      return;
    }

    if (lead.aguardandoAssinaturaCliente) {
      modalAguardandoAssinaturaClienteRef.current?.open();
      return;
    }

    if (!lead.isFromParceiro || !lead.aguardandoAssinaturaCliente) {
      nextStep();
      return;
    }
  };

  const putReproveLead = useMutation({
    mutationFn: changeStatusLead,
    mutationKey: ["put-reprove-lead"],
    onSuccess: (data) => {
      nextStep();
    },
    onError(erro: AxiosError) {
      console.log(erro);
    },
  });
  //CONTINUAR CADASTRO:
  const handleContinueCadastro = () => {
    setContinueResgistration(true);
    setItemSession("emailContinue", email);
    initAuth.mutate({ email });
  };

  const handleReproveLead = () => {
    putReproveLead.mutate(email);
  };

  const handleBackButtonClick = () => {
    if (noEditEmail) return navigate("/confirm-your-data");
    navigate("/");
  };

  const creatContactLocalStorage = () => {
    const existContact = getItemSession("contact");
    if (existContact) {
      localStorage.removeItem(existContact);
    }

    const contactData = {
      name,
      email,
      phone,
      acceptTime: new Date().toString(),
    };
    const contactDataString = JSON.stringify(contactData);
    setItemSession("contact", contactDataString);
  };

  const handleButtonClick = () => {
    creatContactLocalStorage();
    if (noEditEmail) {
      return navigate("/confirm-your-data");
    }
    if (continueEmail) {
      return navigate("/holder");
    }

    existLead.mutate(email);
  };

  const nextStep = () => {
    if (mode === "SELLER") {
      const storedContact = getItemSession("contact");
      const distribuidora = getItemSession("distribuidora") as string;
      const storedDadosParceiro = getItemSession("DadosParceiro");
      if (!storedContact || !storedDadosParceiro) return;
      const { email, name, phone, acceptTime } = JSON.parse(storedContact);
      const dadosParceiro: DadosParceiroType = JSON.parse(storedDadosParceiro);
      sessionStorage.setItem("contactEmail", email);

      createLead.mutate({
        nome: name,
        telefone: phone,
        distribuidora,
        parceiroId: getParceiroId(),
        ...dadosParceiro,
      });
    } else {
      initAuth.mutate({ email, name });
    }
  };

  const isLoading =
    existLead.isPending ||
    initAuth.isPending ||
    putReproveLead.isPending ||
    createLead.isPending;

  return !statusLead ? (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={texts.contact.title}
        description={texts.contact.description}
        imgSrc={assets.contact.image}
      />
      <RightHomeComponent
        title={texts.contact.title}
        description={texts.contact.description}
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-contact"
      >
        <div className="container-contact-form">
          <ContactForm
            continueEmail={continueEmail}
            noEditEmail={noEditEmail}
            setFormData={setFormData}
            setFormValidation={setFormValid}
          />
          {mode === "CUSTOMER" && (
            <div className="checkbox-container">
              <input
                type="checkbox"
                onChange={(e) => {
                  console.log(e);
                  setIsPoliticaAceita(e.target.checked);
                }}
              />
              <div>
                Li e aceito os{" "}
                <span className="termos-de-uso-link" onClick={openTermosDeUso}>
                  termos de uso e politica de privacidade
                </span>
              </div>
            </div>
          )}
          {initAuth.isError && (
            <p className="error-mgs-contact">
              Por favor, verifique os dados digitados
            </p>
          )}
          <div className="container-button-contact">
            <ButtonComponent
              className="back-button"
              // className="btn-voltar-contact"
              onClick={handleBackButtonClick}
              isDisabled={isLoading}
            >
              Voltar
            </ButtonComponent>

            <ButtonComponent
              className="continue-button"
              // className="btn-continue-contact"
              onClick={handleButtonClick}
              isDisabled={
                !formValid ||
                (!isPoliticaAceita && mode === "CUSTOMER") ||
                isLoading
              }
            >
              Continuar
            </ButtonComponent>
          </div>
        </div>
        <ModalEmailExistente
          handleContinueCadastro={handleContinueCadastro}
          handleReproveLead={handleReproveLead}
          ref={modalEmailExistenteRef}
        />
        <ModalAguardandoAssinaturaCliente
          ref={modalAguardandoAssinaturaClienteRef}
        />
        <ModalTermosDeUso ref={modalTermosDeUsoRef} />

        {isLoading ? <LoadingFullPage /> : null}
      </RightHomeComponent>
    </MainHomeComponent>
  ) : (
    <ScreenError
      icon={PdfAlert}
      alt="Pdf com uma exclamação"
      texto="Você possui uma fatura em análise, em breve daremos um retorno."
      title="Validação de Fatura"
    />
  );
};
