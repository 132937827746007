export const SESSION_KEYS = [
  "distribuidora",
  "emailContinue",
  "term",
  "contact",
  "holderData",
  "holderCNPJ",
  "cnpj",
  "cpf",
  "dados-fatura",
  "faturaBase64",
  "TarifaVigente",
  "DescontoPadrao",
  "dados-distribuidora",
  "tipo-instalacao",
  "formData",
  "is-client-from-parceiro",
  "DadosParceiro",
  "reproveLead",
  "isSigner"
] as const;
