import React, { useCallback, useRef, useState } from "react";
import { classMerge } from "../../utils/cn";
import { ImBin2 } from "react-icons/im";
import { motion, HTMLMotionProps } from "framer-motion";
import { bytesToMegabytes } from "../../utils/bytesToMegabytes";

type FileUploadCardProps = {
  onFileUpload: (file: File) => void;
  onButtonClick?: () => void;
  onRemoveFile: (idx: number) => void;
  files: File[];
  isMultiple?: boolean;
  acceptedFileTypes?: string[];
  maxFileSize?: number;
} & React.ComponentProps<"div">;

const DEFAULT_ACCEPTED_FILE_TYPES = ["pdf", "jpg", "png", "jpeg"];

const FileUploadCard = ({
  onFileUpload,
  onButtonClick,
  onRemoveFile,
  acceptedFileTypes = DEFAULT_ACCEPTED_FILE_TYPES,
  files,
  isMultiple = false,
  children,
  className,
  maxFileSize = 25,
  ...props
}: FileUploadCardProps) => {
  const [_, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(true);
    },
    [setIsDragging]
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(true);
    },
    [setIsDragging]
  );

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    if (!file) return;

    const fileType = file.type.split("/")[1];

    const isAcceptedFileType = acceptedFileTypes.includes(fileType);
    const isAcceptedFileSize = bytesToMegabytes(file.size) <= maxFileSize;
    if (!isAcceptedFileSize || !isAcceptedFileType) return;

    if (!isMultiple && files.length < 1) {
      onFileUpload(file);
      return;
    }

    if (isMultiple) onFileUpload(file);
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const filesList = event.target.files;
    if (filesList === null || filesList?.length === 0) return;

    const acceptedFiles = Array.from(filesList).filter((file) => {
      const fileSize = bytesToMegabytes(file.size);
      const fileType = file.type.split("/")[1];
      return acceptedFileTypes.includes(fileType) && fileSize <= maxFileSize;
    });

    if (acceptedFiles.length === 0) return;

    if (!isMultiple && files.length < 1) {
      onFileUpload(acceptedFiles[0]);
      event.target.value = "";
      return;
    }

    if (isMultiple) {
      acceptedFiles.forEach((file) => onFileUpload(file));
      event.target.value = "";
    }
  };

  const handleRemoveFile = (idx: number) => {
    onRemoveFile(idx);
  };

  const handleCardClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className={classMerge(
        "bg-white shadow-[0px_1px_2px_0px_rgba(0,0,0,0.1)] rounded-lg text-center flex flex-col justify-center px-6 py-6",
        className
      )}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleCardClick}
      {...props}
    >
      {(files.length === 0 && !isMultiple) || isMultiple ? (
        <>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
            accept="application/pdf, image/jpg, image/png, image/jpeg"
            multiple={isMultiple}
          />
          {children}
        </>
      ) : null}

      {files.length > 0
        ? files.map((file, idx) => (
            <div
              className="flex items-center justify-center overflow-hidden 
              [&>span]:overflow-hidden [&>span]:whitespace-nowrap [&>span]:text-overflow-ellipsis [&>span]:inline-block [&>span]:w-[70%] 
              [&>button]:cursor-pointer [&>button]:transition-all [&>button]:hover:duration-300 [&>button]:hover:-translate-y-[3px]"
              key={file.name + idx}
            >
              <span>{file.name}</span>
              <button
                className="px-4 py-2"
                onClick={(evt) => {
                  evt.stopPropagation();
                  handleRemoveFile(idx);
                }}
              >
                <ImBin2 />
              </button>
            </div>
          ))
        : null}
    </div>
  );
};

type FileUploadCardButtonProps = HTMLMotionProps<"button">;

const FileUploadCardButton = ({
  children,
  className,
  ...props
}: FileUploadCardButtonProps) => {
  return (
    <motion.button
      className={classMerge(
        "btn-contact max-w-[220px] mx-auto mt-3 [&>span]:!text-[var(--continue-button-font)] font-kumbh-sans [&>span]:!font-kumbh-sans",
        className
      )}
      {...props}
    >
      {children || "Procure o Arquivo"}
    </motion.button>
  );
};

export { FileUploadCard, FileUploadCardButton };
