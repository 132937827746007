import { Input } from "@nextui-org/react";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { PDFDocument } from "pdf-lib";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import { EyeFilledIcon } from "../../assets/eyesIcon/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../../assets/eyesIcon/EyeSlashFilledIcon";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import {
  FileUploadCard,
  FileUploadCardButton,
} from "../../components/card/Card";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import { InstallationRegistered } from "../../components/modal/InstallationRegistered/InstallationRegistered";
import Modal from "../../components/modal/Modal";
import { NewModalRef } from "../../components/modal/NewModal/NewModal";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import { SessionContext } from "../../context/session";
import { useGetDistribuidora } from "../../hooks/useGetDistribuidora";
import {
  POST_CONFIRMAR_PARSE_FATURA,
  POST_MANUAL_FATURA,
  POST_PARSE_FATURA,
} from "../../query/constants";
import { getImage } from "../../services/images";
import { validateManual } from "../../services/lead";
import { confirmarDadosFatura, parseFatura } from "../../services/onboarding";
import { textsPath } from "../../services/texts";
import ErrorReadPDFModal from "./ErrorReadPDFModal/ErrorReadPDFModal";
import "./installation.css";
import { usePartnerStore } from "../../partner/use-partner-store";
import { MdCloudUpload } from "react-icons/md";
import { classMerge } from "../../utils/cn";
import { useAddAdditionalFiles } from "../../hooks/useAddAdditionalFiles";

export const Installation = () => {
  const navigate = useNavigate();
  const { setItemSession, getItemSession } = useContext(SessionContext);
  const [open, setOpen] = useState(false);
  // const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const [errorMgs, setErrorMgs] = useState("");
  const [additionalFilesError, setAdditionalFilesError] = useState("");
  // const [emptyFile, setEmptyFile] = useState<boolean>(true);
  const [cnpjFlux, setCnpjFlux] = useState<boolean>(false);
  const [passwordPdf, setPasswordPdf] = useState<string>("");

  const [faturasFiles, setFaturasFiles] = useState<File[]>([]);
  const [additionalsFiles, setAdditionalsFiles] = useState<File[]>([]);

  const [erroPassword, setErroPassword] = useState<boolean>(false);
  const cnpj = getItemSession("cnpj");
  const errorReadPDFModalRef = useRef<NewModalRef>(null);

  const { mutateAsync: addAddtionalFiles, ...addAdditionalFiles } =
    useAddAdditionalFiles();

  const manualvalidate = useMutation({
    mutationFn: validateManual,
    mutationKey: [POST_MANUAL_FATURA],
    onSuccess: (data) => {
      navigate("/pdf-error-validation");
    },
    onError: (erro) => {
      console.log(erro);
    },
  });
  const distribuidora = useGetDistribuidora();

  const mutationConfirmationParseFatura = useMutation({
    mutationFn: confirmarDadosFatura,
    mutationKey: [POST_CONFIRMAR_PARSE_FATURA],
    // onSuccess(data) {
    //   if (data.status === "INPUT_INVALIDO") {
    //     throw new Error(data.erros);
    //   } else if (data.status === "FALHA") {
    //     throw new Error(data.erro);
    //   }
    //   navigate("/confirm-your-data");
    // },
    onError(erro) {
      console.log(erro.message);
    },
  });

  const mutationParseFatura = useMutation({
    mutationFn: parseFatura,
    mutationKey: [POST_PARSE_FATURA],
    onSuccess: async (data, formData) => {
      // console.log(data);

      if (data.status !== "SUCESSO") {
        throw new Error(data.erro);
      }
      if (
        data.status === "SUCESSO" &&
        data.statusCadastro === "INSTALACAO_EXISTENTE"
      ) {
        return setOpen(true);
      }
      if (
        data.status === "SUCESSO" &&
        data.statusCadastro === "BAIXO_CONSUMO"
      ) {
        return navigate("/unapproved-account");
      }

      setDadosInstalacao(data.fatura);
      const confirmationParseFaturaResponse =
        await mutationConfirmationParseFatura.mutateAsync(formData);

      if (confirmationParseFaturaResponse.status === "INPUT_INVALIDO") {
        throw new Error(confirmationParseFaturaResponse.erros);
      }

      if (confirmationParseFaturaResponse.status === "FALHA") {
        throw new Error(confirmationParseFaturaResponse.erro);
      }

      if (additionalsFiles.length === 0) {
        navigate("/confirm-your-data");
        return;
      }

      try {
        await addAddtionalFiles(additionalsFiles);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setAdditionalFilesError(error.response?.data.message || "");
          return;
        }
        setAdditionalFilesError("Erro ao fazer upload dos arquivos.");
        return;
      }

      navigate("/confirm-your-data");
    },
    onError(erro: AxiosError) {
      const erroStatus = erro.response?.status;
      const erroData = erro.response?.data as { status: string };
      console.log(erroStatus);
      if (erroStatus === 422) {
        // serErrorReadPdf(true);
        errorReadPDFModalRef.current?.open();
        return;
      }
      if (erroStatus === 400 && erroData?.status === "wrong_password") {
        setErrorMgs("Senha incorreta");
        setErroPassword(true);
        return;
      }
    },
  });

  function setDadosInstalacao(fatura: any) {
    setItemSession("dados-fatura", JSON.stringify(fatura));
  }

  useEffect(() => {
    if (cnpj != null) {
      setCnpjFlux(true);
    }
  }, [cnpj]);

  const transformData = (file: File) => {
    const formData = new FormData();

    formData.append("File", file);
    return formData;
  };

  const handleFaturaFileUpload = (file: File) => {
    // setEmptyFile(false);
    setErrorMgs("");
    // setUploadedFileName(file.name);
    setFaturasFiles((prev) => [...prev, file]);
  };

  const handleRemoveFaturaFile = () => {
    // Lógica para remover o arquivo
    // setEmptyFile(true);
    // setUploadedFileName(null);
    setFaturasFiles([]);
  };

  const isPdfProtect = async (file: File): Promise<boolean> => {
    const uint8Array = await file.arrayBuffer();
    try {
      const fileDocument = await PDFDocument.load(uint8Array, {
        ignoreEncryption: true,
      });
      if (fileDocument.isEncrypted) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleButtonClick = async () => {
    setAdditionalFilesError("");

    if (faturasFiles.length === 0) {
      return setErrorMgs("Faça o upload da sua conta de energia.");
    }

    if (faturasFiles.length === 0) return;

    if ((await isPdfProtect(faturasFiles[0])) && !passwordPdf) {
      setErroPassword(true);
      setErrorMgs("Informe a senha do seu arquivo");
      return;
    }
    const formData = transformData(faturasFiles[0]);
    if (!formData) return;
    if (passwordPdf && faturasFiles) {
      formData.append("Password", passwordPdf);
    }
    distribuidora.mutate();
    mutationParseFatura.mutate(formData);
  };

  function handlePasswordPdf(event: ChangeEvent<HTMLInputElement>) {
    setPasswordPdf(event.target.value);
    if (passwordPdf.length === 3) {
      setErroPassword(false);
      setErrorMgs("");
    }
  }

  const handleBackButtonClick = () => {
    if (cnpjFlux) {
      navigate("/create-holder-cnpj");
      return;
    }
    navigate("/create-holder");
  };

  const handleButtonClickOtherInstallation = () => {
    handleRemoveFaturaFile();
    setOpen(false);
  };

  const handleValidationManualFatura = () => {
    if (faturasFiles.length === 0) return;
    const formData = transformData(faturasFiles[0]);
    if (!formData) return;
    if (passwordPdf && faturasFiles) {
      formData.append("Password", passwordPdf);
    }
    manualvalidate.mutate(formData);
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const { assets, texts } = usePartnerStore();

  // Additional files
  const handleUploadAdditionalsFiles = (file: File) => {
    setAdditionalFilesError("");
    setAdditionalsFiles((prev) => [...prev, file]);
  };

  const handleRemoveAdditionalFile = (index: number) => {
    setAdditionalFilesError("");
    setAdditionalsFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={texts.installation.title}
        description={texts.installation.description}
        imgSrc={assets.installation.image}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-installation gap-3"
        title={texts.installation.title}
        description={texts.installation.description}
      >
        <FileUploadCard
          onFileUpload={handleFaturaFileUpload}
          onRemoveFile={handleRemoveFaturaFile}
          files={faturasFiles}
          className="w-full max-w-[320px] min-h-[12rem]"
        >
          <p className="leading-6 px-0 font-bold">
            Conta de Energia
            <span className="block !text-zinc-500 !font-normal">
              Adicione um arquivo em PDF ou imagem (PNG ou JPG)
            </span>
          </p>
          <FileUploadCardButton className="min-w-full" />
        </FileUploadCard>

        {faturasFiles.length === 0 && errorMgs && (
          <p className="error-empty-file">{errorMgs}</p>
        )}

        {erroPassword && <p className="error-error-file">{errorMgs}</p>}
        <div className="container-input-password !m-0 w-full max-w-[320px]">
          <Input
            isInvalid={erroPassword}
            value={passwordPdf}
            onChange={handlePasswordPdf}
            label="Senha do arquivo (opcional)"
            color="primary"
            size="md"
            endContent={
              <button
                className="focus:outline-none py-2 "
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none " />
                ) : (
                  <EyeFilledIcon className="text-2xl text-default-400  pointer-events-none  " />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
            classNames={{
              label: ["text-normal"],
              inputWrapper: [
                "bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent ",
              ],
              input: ["text-base"],
            }}
          />
        </div>

        <div className="mt-6">
          <FileUploadCard
            onFileUpload={handleUploadAdditionalsFiles}
            onRemoveFile={handleRemoveAdditionalFile}
            files={additionalsFiles}
            isMultiple={true}
            className="min-h-[10rem] w-full max-w-[320px]"
          >
            <p className="leading-6 px-0 font-bold">
              Documentos Complementares
              <span className="block !text-zinc-500 !font-normal">
                Ex.: Protocolo de troca de titularidade, procurações, atas de
                assembleias, contratos sociais, etc.
              </span>
            </p>
            <FileUploadCardButton
              data-empty={additionalsFiles.length === 0}
              className="min-w-full p-3 flex items-center justify-center gap-x-4 data-[empty=true]:flex-col data-[empty=false]:flex-row group"
            >
              <span className="group-data-[empty=true]:block group-data-[empty=false]:hidden">
                Procure o Arquivo
              </span>
              <span className="group-data-[empty=true]:hidden group-data-[empty=false]:block">
                Adicionar mais
              </span>
            </FileUploadCardButton>
          </FileUploadCard>

          {additionalFilesError && (
            <p className="error-empty-file">{additionalFilesError}</p>
          )}
        </div>

        <div className="button-container-installation">
          <ButtonComponent
            className="back-button"
            onClick={handleBackButtonClick}
          >
            Voltar
          </ButtonComponent>

          <ButtonComponent
            className="continue-button"
            onClick={handleButtonClick}
            isDisabled={faturasFiles.length === 0}
          >
            Continuar
          </ButtonComponent>
        </div>
        {open && (
          <div className="modal-position">
            <Modal
              onClose={() => setOpen(false)}
              style={{
                width: "398px",
                height: "292px",
                display: "flex",
                flexDirection: "column",
              }}
              className="container-children-installation"
            >
              <InstallationRegistered />
              <div className="button-modal-container-email">
                <ButtonComponent
                  className="back-button"
                  onClick={() => setOpen(false)}
                >
                  Fechar
                </ButtonComponent>
                <ButtonComponent
                  className="continue-button"
                  onClick={handleButtonClickOtherInstallation}
                  isDisabled={false}
                  style={{
                    width: "192px",
                  }}
                >
                  Cadastrar outra conta
                </ButtonComponent>
              </div>
            </Modal>
          </div>
        )}

        <ErrorReadPDFModal
          ref={errorReadPDFModalRef}
          handleValidationManualFatura={handleValidationManualFatura}
        />

        {(mutationParseFatura.isPending ||
          mutationConfirmationParseFatura.isPending ||
          manualvalidate.isPending ||
          addAdditionalFiles.isPending) && <LoadingFullPage />}
        {/* {manualvalidate.isPending && <LoadingFullPage />} */}
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
