import { useMutation } from "@tanstack/react-query";
import { POST_ADDITIONAL_FILES } from "../query/constants";
import { addAdditionalFiles } from "../services/onboarding";

const useAddAdditionalFiles = () => {
  return useMutation({
    mutationFn: async (files: File[]) => {
      const formData = new FormData();
      for (const file of files) formData.append("files", file);
      const response = await addAdditionalFiles(formData);
      return response;
    },
    mutationKey: [POST_ADDITIONAL_FILES],
    onError: (error) => {
      console.error("Error adding files", error);
    },
  });
};

export { useAddAdditionalFiles };
