import React, { ReactNode, MouseEvent, useRef } from "react";
import iconClose from "../../assets/close_line.svg";
import "./Modal.css"; // Importe o arquivo CSS específico para estilos
interface ModalProps {
  onClose: () => void;
  children: ReactNode;
  className?: string;
  classNameModal?: string;
  style?: React.CSSProperties;
  id?: string;
  isDismissable?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  className,
  classNameModal = "modal-content",
  id,
  style,
  isDismissable = true,
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target as Node) &&
      isDismissable
    ) {
      onClose();
    }
  };
  return (
    <div className="modal-container" onClick={handleClickOutside}>
      <div
        id={id}
        className={classNameModal}
        ref={modalContentRef}
        style={style}
      >
        {/* Adicione o ícone de fechamento */}
        {isDismissable && (
          <div className="close-icon" onClick={onClose}>
            <img src={iconClose} alt="icone letra x, em cor cinza" />
          </div>
        )}
        {/* Conteúdo da sua modal */}
        <div className={className}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
